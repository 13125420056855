import { AspectRatio, Box, Button, Grid, Typography } from "@mui/joy";
import React from "react";
import { Header } from "../components/layout/Header";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { Services } from "../components/Services";
import Footer from "../components/layout/Footer";
import { useNavigate } from "react-router-dom";

export function AboutPage() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Header></Header>
      <Grid bgcolor={"#0e2330"} px={4}>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          maxWidth={1280}
          columns={2}
          m={"auto"}
          py={12}
          sx={{
            "@media (max-width: 900px)": {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            flex={1}
            mr={4}
            sx={{
              "@media (max-width: 900px)": {
                mr: 0,
                mb: 4,
              },
            }}
          >
            <AspectRatio ratio="1">
              <img src={require("../images/quem-somos.png")}></img>
            </AspectRatio>
          </Grid>
          <Grid flex={1}>
            <Typography mb={2} sx={{ color: "#FFFFFF" }}>
              Na Comercial Solar, acreditamos que o poder da transformação
              começa com a energia do sol. Nosso propósito é redefinir o futuro
              energético, tornando-o mais sustentável e eficiente. Como
              pioneiros no setor, buscamos proporcionar soluções inovadoras e
              integradas em energia solar.
            </Typography>
            <Typography mb={2} sx={{ color: "#FFFFFF" }}>
              Nosso compromisso é liderar a transição para um mundo mais limpo e
              ecológico, indo além das expectativas dos nossos clientes. Cada
              projeto que abraçamos não é apenas um empreendimento, mas uma
              verdadeira contribuição para um futuro mais brilhante. Na Comercial
              Solar, acreditamos no poder transformador da energia solar para
              impulsionar uma mudança positiva.
            </Typography>
            <Typography sx={{ color: "#FFFFFF" }}>
              Na Comercial Solar, não fornecemos apenas soluções sustentáveis;
              integramos práticas ecológicas em cada aspecto do nosso negócio.
              Desde a escolha de materiais até a execução de projetos, estamos
              empenhados em minimizar nosso impacto ambiental e contribuir para
              um futuro mais sustentável.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid bgcolor={"#0e2330"} px={4}>
        <Grid
          display={"flex"}
          maxWidth={1280}
          m={"auto"}
          columns={3}
          py={12}
          gap={4}
          sx={{
            "@media (max-width: 900px)": {
              flexDirection: "column",
              pt: 0,
            },
          }}
        >
          <Grid
            flex={1}
            sx={{
              ":hover": { bgcolor: "#4caf50" },
              transitionDuration: "0.2s",
              cursor: "pointer",
            }}
            border={1}
            borderColor={"#0e2330"}
            bgcolor={"#0e2330"}
            boxShadow={"0px 8px 24px -2px rgba(0,0,0,0.31)"}
            p={4}
          >
            <Typography level="h2" mb={2} sx={{ color: "#FFFFFF" }}>
              Missao
            </Typography>
            <Typography sx={{ color: "#FFFFFF" }}>
              Proporcionar soluções inovadoras em energia renovável,
              especialmente no segmento de energia solar fotovoltaica, de
              maneira sustentável e rentável. Nosso compromisso é contribuir
              para a construção de um futuro mais limpo e promissor, atendendo
              às necessidades de nossos clientes e promovendo a transição para
              um modelo energético mais verde.
            </Typography>
          </Grid>
          <Grid
            flex={1}
            border={1}
            borderColor={"#0e2330"}
            bgcolor={"#0e2330"}
            boxShadow={"0px 8px 24px -2px rgba(0,0,0,0.31)"}
            p={4}
            sx={{
              ":hover": { bgcolor: "#4caf50" },
              transitionDuration: "0.2s",
              cursor: "pointer",
            }}
          >
            <Typography level="h2" mb={2} sx={{ color: "#FFFFFF" }}>
              Visao
            </Typography>
            <Typography sx={{ color: "#FFFFFF" }}>
              Almejamos ser reconhecidos como líderes em excelência no setor de
              energia renovável, destacando-nos perante investidores, clientes,
              fornecedores, colaboradores e a sociedade em geral. Buscamos
              consolidar nossa posição como referência no mercado brasileiro de
              energia distribuída, pautando nossa atuação na qualidade, inovação
              e compromisso com a sustentabilidade.
            </Typography>
          </Grid>
          <Grid
            flex={1}
            border={1}
            borderColor={"#0e2330"}
            bgcolor={"#0e2330"}
            boxShadow={"0px 8px 24px -2px rgba(0,0,0,0.31)"}
            p={4}
            sx={{
              ":hover": { bgcolor: "#4caf50" },
              transitionDuration: "0.2s",
              cursor: "pointer",
            }}
          >
            <Typography level="h2" mb={2} sx={{ color: "#FFFFFF" }}>
              VALORES
            </Typography>
            <List marker={"disc"} sx={{ pl: 3, color: "#FFFFFF" }}>
              <ListItem sx={{ color: "#FFFFFF" }}>
              Compromisso com Clientes
              </ListItem>
              <ListItem sx={{ color: "#FFFFFF" }}>
              Integridade Ambiental
              </ListItem>
              <ListItem sx={{ color: "#FFFFFF" }}>
              Ética e transparência
              </ListItem>
              <ListItem sx={{ color: "#FFFFFF" }}>
              Foco em resultados
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid bgcolor={"#112A3A"}>
        <Grid maxWidth={1280} m={"auto"} columns={1} py={12}>
          <Grid xs={1}>
            <Typography
              level="h1"
              textAlign={"center"}
              sx={{ color: "#FFFFFF" }}
            >
              VANTAGENS DA ENERGIA SOLAR
            </Typography>
          </Grid>
          <Services></Services>
        </Grid>
      </Grid> */}
      <Grid
        height={600}
        container
        sx={{
          backgroundImage: "url(./AdobeStock_82260045.jpeg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
        columns={1}
      >
        <Grid
          px={4}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 50,
            top: 0,
            background:
              " linear-gradient(63deg, rgba(3,28,56,0.5074404761904762) 13%, rgba(3,28,56,0.9780287114845938) 71%);",
          }}
        >
          <Grid
            maxWidth={1280}
            m={"auto"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height="100%"
          >
            <Grid mb={4}>
              <Typography
                fontSize={48}
                fontWeight={700}
                textAlign={"center"}
                sx={{ color: "#FFFFFF" }}
              >
                Simulador de economia
              </Typography>
            </Grid>
            <Grid textAlign={"center"}>
              <Typography fontSize="xl" sx={{ color: "#F1F1F1" }} mb={2}>
                Explore as possibilidades de crescimento financeiro de forma
                fácil e confiável.{" "}
              </Typography>
              <Typography fontSize="xl" sx={{ color: "#F1F1F1" }} mb={4}>
                Simule agora e descubra o caminho para maximizar seus
                investimentos com Comercial Solar.
              </Typography>
            </Grid>
            <Grid textAlign={"center"}>
              <Button
                sx={{ bgcolor: "#4caf50", ":hover": { bgcolor: "#3f8f42" } }}
                onClick={() => navigate("/simulador-de-economia")}
                size="lg"
              >
                Quero simular agora
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
