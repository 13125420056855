import axios from "axios";

export async function createQuotes(
  name: string,
  email: string,
  phone: string,
  localInstallationType: string,
  country: string,
  city: string,
  company: boolean,
  accountValue: number
) {
  try {
    const res = await axios.post(
      `https://api-solar-9lhh.onrender.com/quotes/create`,
      {
        name: name,
        email:   email,
        phone: phone,
        local_installation_type: localInstallationType,
        country: country,
        city: city,
        company: company,
        account_value: accountValue,
      }
    );
    console.log(res.data);
    if (res.status === 202) {
      if (res.data.success) {
        console.log(res.data);
        return res.data;
      }
    }
    return [];
  } catch (error) {
    return [];
  }
}
