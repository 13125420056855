import React, { useEffect, useState } from "react";
import "./App.css";
import { Header } from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Steps from "./components/Steps";
import Carousel from "./components/Carousel";
import PerguntasFrequentes from "./components/PerguntasFrequentes";


import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import HeaderCarousel from "./components/HeaderCarousel";
import InputMask from "react-input-mask";
import Menu from "./components/Modal/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/styles.css";
import { Beneficios } from "./components/Beneficios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/home'
import Faq from "./pages/faq";
import Simulador from "./pages/simulador";

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import ResultSimulator from "./pages/resultSimulator";
import PrivateRoute from "./pages/PrivateRoute";
import OrcamentoSolicitado from "./pages/OrcamentoSolicitado";
import { ContactPage } from "./pages/Contact";
import { AboutPage } from "./pages/About";
import { HowItWorks } from "./pages/HowItWorks";
import { ServicesPage } from "./pages/Services";
import SolicitarOrcamento from "./pages/OrcamentoSolicitado";
import { OrcamentoObrigado } from "./pages/OrcamentoObrigado";
import { Projetos } from "./pages/Projetos";

const imgHomologacaoServico = require("./images/homologacao-service.png");
const imgInstalacaoServico = require("./images/instalacao-servico.png");
const imgProjetoServico = require("./images/projeto-servico.png");
const imgVendasServico = require("./images/vendas-servico.png");
const imgQuemSomos = require("./images/quem-somos.png");
declare global {
  interface Window {
    gtag: any;
  }
}

function App() {
  useEffect(()=>{
        window.gtag('event', 'conversion', {'send_to': 'AW-11103163243/xi7qCOHqspsZEOums64p'});
  },
  
  [])
  return (
    <BrowserRouter>
    
      <Routes>
        
        <Route element={<Home></Home>} path="/"  />
        
        <Route element={<OrcamentoObrigado></OrcamentoObrigado>} path="/orcamento-solicitado"></Route>
        <Route element={<ServicesPage></ServicesPage>} path="/servicos"  />
        <Route element={<SolicitarOrcamento></SolicitarOrcamento>} path="/solicitar-orcamento"  />
        <Route element={<Projetos></Projetos>} path="/projetos"  />
        <Route element={<AboutPage></AboutPage>} path="/sobre-nos"></Route>
        <Route element={<HowItWorks></HowItWorks>} path="/como-funciona"></Route>
        <Route element={<ContactPage></ContactPage>} path="/contato"></Route>
        <Route element={<Faq></Faq>} path="/faq"  />
        <Route element={<PrivateRoute redirect="/simulador-de-economia"><ResultSimulator></ResultSimulator></PrivateRoute>} path="/resultado-da-simulacao"></Route>
        <Route element={<Simulador></Simulador>} path="/simulador-de-economia"></Route>
        <Route element={<OrcamentoSolicitado></OrcamentoSolicitado>} path="/orcamento-solicitado"></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
