import React from "react";
import Card from "../Card";
import { Grid } from "@mui/joy";

const CarouselItems = (props: any) => {
  const listProjects = [
    {
      name: "Casa em Pojuca/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-pojuca-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-pojuca-2.jpg"),
        },
      ],
    },
    {
      name: "Casa em Salvador/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-itapua-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-itapua-2.jpg"),
        },
      ],
    },
    {
      name: "Casa em Camaçari/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-camacari-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-camacari-2.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-camacari-3.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-camacari-4.jpg"),
        },
      ],
    },
    {
      name: "Casa em Lauro de Freitas/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-lauro-de-freitas-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-lauro-de-freitas-2.jpg"),
        },
      ],
    },
    {
      name: "Casa em Salvador/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-barra-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-barra-2.jpg"),
        },
      ],
    },
    {
      name: "Casa em Dias d'Ávila/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-davila-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-davila-2.jpg"),
        },
      ],
    },
    {
      name: "Casa em São Sebastião do Passé/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-sao-sebastiao-1.jpg"),
        },
      ],
    },
    {
      name: "Casa em Itacimirim/BA",
      images: [
        {
          src: require("../../images/clientes/cliente-Itacimirim-1.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-Itacimirim-2.jpg"),
        },
        {
          src: require("../../images/clientes/cliente-Itacimirim-3.jpg"),
        },
      ],
    },
  ];
  return (
    <Grid container sx={{
      "@media (max-width: 1200px)": {
        gridTemplateColumns: 'repeat(3, 1fr)'
        },
        "@media (max-width: 800px)": {
          gridTemplateColumns: 'repeat(2, 1fr)'
          },
          "@media (max-width: 500px)": {
            gridTemplateColumns: 'repeat(1, 1fr)'
            },
    }} display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={6}  >
      {listProjects.map((e: any) => {
        return (
          <Grid flex={1}
            onClick={() => {
              props.modalIsOpen(true);
              props.setImages(e.images);
            }}
          >
            <Card
              onClick
              setNomeCliente={e.name}
              setImageCliente={e.images[0].src}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CarouselItems;
