import {
  AspectRatio,
  Button,
  Card,
  CardContent,
  CardOverflow,
  Link,
  Typography,
} from "@mui/joy";

export function CardProduct(props: any) {
  return (
    <Card
      variant="solid"
      
      sx={{
        bgcolor: "#0e2330",
        width: "100%",
        height: 400,
        display: "flex",
      }}
    >
      <AspectRatio
        objectFit="contain"
        variant="plain"
        sx={{ width: "100%", height: "100%", display: "flex" }}
      >
        <img
          width="100%"
          height="100%"
          src={props.imageCard}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <CardContent>
        <Link
          href="#product-card"
          fontWeight="md"
          sx={{ color: "#f6f6f6" }}
          overlay
        >
          {props.nameProduct}
        </Link>
      </CardContent>
      <CardOverflow>
        <Button sx={{bgcolor: '#4caf50', ":hover": { bgcolor: "#3f8f42" }}} variant="solid" size="lg">
          Detalhes
        </Button>
      </CardOverflow>
    </Card>
  );
}
