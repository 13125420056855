import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Drawer from '@mui/joy/Drawer';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import ModalClose from '@mui/joy/ModalClose';
import Menu from '@mui/icons-material/Menu';
import Search from '@mui/icons-material/Search';
import { Button, Divider } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export default function DrawerMobileNavigation() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  return (
    <Box >
      <IconButton size='lg' sx={{"@media (max-width: 1200px)": {
                  display: "flex",
                }, display:"none", color: '#FFFFFF'}} variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        <Menu />
      </IconButton>
      <Drawer  open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            
            alignItems: 'center',
            gap: 0.5,
            ml: 'auto',
            mt: 1,
            mr: 2,
          }}
        >
          <Typography
            component="label"
            htmlFor="close-icon"
            fontSize="sm"
            fontWeight="lg"
            sx={{ cursor: 'pointer' }}
          >
          </Typography>
          <ModalClose id="close-icon" sx={{ position: 'initial' }} />
        </Box>
        <List
          size="lg"
          component="nav"
          sx={{
            flex: 'none',
            fontSize: 'xl',
            '& > div': { justifyContent: 'center' },
          }}
        >
          <ListItemButton onClick={()=> navigate('/')} sx={{ fontWeight: 'lg' }}>Inicio</ListItemButton>
          <ListItemButton onClick={()=> navigate('/sobre-nos')}>Quem somos</ListItemButton>
          <ListItemButton onClick={()=> navigate('/como-funciona')}>Como funciona</ListItemButton>
          <ListItemButton onClick={()=> navigate('/servicos')}>Serviços</ListItemButton>
          <ListItemButton onClick={()=> navigate('/projetos')}>Projetos</ListItemButton>
          <ListItemButton onClick={()=> navigate('/faq')}>FAQ</ListItemButton>
          <ListItemButton onClick={()=> navigate('/contato')}>Contato</ListItemButton>
          <Divider sx={{backgroundColor: '#000000', mt: 4, mb: 4, mx: 2}}></Divider>
          <Button onClick={()=> navigate('/solicitar-orcamento')} variant='outlined' size='lg' sx={{mx:4, borderRadius: 'lg',   borderColor: '#4CAF50', color: '#4CAF50', ":hover": {bgcolor: '#4CAF50', color: '#FFFFFF'}}} >Solicitar Orçamento</Button>
        </List>
      </Drawer>
    </Box>
  );
}