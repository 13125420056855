import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Beneficios } from "../components/Beneficios";
import { Box, Stack } from "@mui/material";
import Steps from "../components/Steps";
import { Header } from "../components/layout/Header";
import Carousel from "../components/Carousel";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import PerguntasFrequentes from "../components/PerguntasFrequentes";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/joy";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Services } from "../components/Services";
import { FavoriteBorder } from "@mui/icons-material";
import { Testimonial } from "../components/Testimonials";
import Footer from "../components/layout/Footer";
import { CardProduct } from "../components/CardProducts";
const imgHomologacaoServico = require("../images/homologacao-service.png");
const imgInstalacaoServico = require("../images/instalacao-servico.png");
const imgProjetoServico = require("../images/projeto-servico.png");
const imgVendasServico = require("../images/vendas-servico.png");
const imgQuemSomos = require("../images/quem-somos.png");
const products = [
  {
    name: "MODUL TRINA TSM-DE19R 575 30MM",
    img: require("../images/Produtos/MODUL-TRINA-TSM-DE19R-575-30MM.png"),
  },
  {
    name: "INVERSOR SUNGROW 5.0RS",
    img: require("../images/Produtos/INVERSOR-SUNGROW-5.0RS.png"),
  },

  {
    name: "MOD JA SOLAR JAM72D30-545/MB",
    img: require("../images/Produtos/MOD-JA-SOLAR-JAM72D30-545MB.png"),
  },
  {
    name: "MICRO APSYSTEMS DS3D-BR 2000W",
    img: require("../images/Produtos/MICRO-APSYSTEMS-DS3D-BR-2000W.png"),
  },
  {
    name: "MODULO TRINA TSM-DE21 665 33MM",
    img: require("../images/Produtos/MODUL-TRINA-TSM-DE19R-575-30MM.png"),
  },
  {
    name: "INVERSOR SUNGROW 6.0RS",
    img: require("../images/Produtos/INVERSOR-SUNGROW-5.0RS.png"),
  },
];
const Home = () => {
  const navigate = useNavigate()
  const [images, setImages] = useState([]);
  const [indexImageAtual, setIndexImageAtual] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    function handleScroll(e: any) {
      const contentSection: any = document.querySelectorAll(".content-section");

      contentSection.forEach((element: any) => {
        const windowTop = element.offsetTop - window.innerHeight * 0.85;

        if (window.pageYOffset > windowTop) {
          element.classList.remove("opacity-0");
          element.classList.remove("translate-y-2/4");
        }
      });
    }

    for (let i = 1; i < 6; i++) {
      let carregamentoPassos = document.getElementById(
        "carregamento-passo-" + i
      );
      let passos = document.getElementById("passo-" + i);
      setTimeout(() => {
        if (passos) {
          passos.classList.remove("border-[#cdcdcd]");
          passos.classList.add("border-[#4caf50]");
        }
        if (carregamentoPassos) {
          carregamentoPassos.classList.remove("before:w-0");
          carregamentoPassos.classList.add("before:w-full");
        }
      }, 2000 * i);
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modalIsOpen]);

  const getImagesSlide = (data: any) => {
    setImages(data);
  };
  return (
    <div className="relative overflow-hidden bg-slate-100">
      <Lightbox
        open={modalIsOpen}
        plugins={[Fullscreen]}
        close={() => setModalIsOpen(false)}
        slides={images}
      />
      <Box sx={{ width: "100%", position: "relative" }}>
        <Header headerCenter={false} headerBanner={true}></Header>
        <Grid bgcolor={"#112A3A"}>
          <Grid maxWidth={1600} m={"auto"} py={4}>
            <Typography
              fontSize={48}
              fontWeight={700}
              textAlign={"center"}
              sx={{
                color: "#ffffff",
                "@media (max-width: 400px)": {
                  fontSize: 40,
                },
              }}
            >
              Beneficios
            </Typography>
            <Typography
              mb={8}
              mt={2}
              fontWeight={500}
              textAlign={"center"}
              sx={{ color: "#4caf50" }}
            >
              Conheça os beneficios de instalar energia solar em sua casa ou
              empresa
            </Typography>
            <Grid
              sx={{
                "@media (max-width: 1200px)": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              display={"flex"}
              gap={4}
              spacing={4}
              direction="row"
              m={"auto"}
              position={"relative"}
            >
              <Grid flex={1} p={4} borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    mx: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  <AttachMoneyOutlinedIcon />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Economia
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#f6f6f6" }}
                  textAlign={"center"}
                >
                  {/* A economia pode chegar até 95% da sua fatura mensal */}
                </Typography>
              </Grid>
              <Grid flex={1} p={4} borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  <StarPurple500OutlinedIcon />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Valorização do seu imóvel​
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#f6f6f6" }}
                  textAlign={"center"}
                >
                  {/* Energia solar valoriza propriedades devido à economia gerada,
                  sendo um atrativo para compradores preocupados com custos a
                  longo prazo. */}
                </Typography>
              </Grid>
              <Grid flex={1} p={4} borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  <EnergySavingsLeafOutlinedIcon />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Sustentabilidade​
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#f6f6f6" }}
                  textAlign={"center"}
                >
                  {/* Seus produtos e serviços utilizarão energia limpa, reduzindo
                  assim a emissão de CO2 na atmosfera. */}
                </Typography>
              </Grid>
              <Grid flex={1} p={4} borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  <SolarPowerOutlinedIcon />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Vida Útil​
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#f6f6f6" }}
                  textAlign={"center"}
                >
                  {/* Os equipamentos fotovoltaicos tem garantias de performance de
                  25 anos. A vida útil do sistema é de 30 a 35 anos. */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack
          sx={{
            width: "100%",
            backgroundImage: `url(${require("../images/casa-energia-solar.jpeg")})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `cover`,
            backgroundPosition: `left top`,
          }}
        >
          <Grid
            px={4}
            sx={{
              "@media (max-width: 1200px)": {
                py: 6,
              },
              background:
                " linear-gradient(63deg, rgba(3,28,56,0.5074404761904762) 13%, rgba(3,28,56,0.9780287114845938) 71%);",
            }}
          >
            <Grid container maxWidth={1280} m={"auto"}>
              <Grid
                sx={{
                  "@media (max-width: 1200px)": {
                    display: "none",
                    flex: 0,
                    height: 0,
                  },
                }}
                flex={1}
                display={"flex"}
                height={600}
              >
                <img src="./mulher-economia.png" alt="" />
              </Grid>
              <Grid
                width={"100%"}
                sx={{
                  "@media (max-width: 1200px)": {
                    ml: 0,
                  },
                }}
                flex={1}
                ml={42}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography level="h1" mb={4} sx={{ color: "#ffffff" }}>
                  A energia solar é a chave para um futuro sustentável
                </Typography>
                <Typography mb={4} sx={{ color: "#ffffff" }}>
                  Orçamento feito na medida para você! Economia de até 95% na
                  sua conta de luz e impacto positivo para todo mundo.
                </Typography>
                <Button
                onClick={()=>{navigate('/simulador-de-economia')}}
                  size="lg"
                  sx={{ bgcolor: "#4caf50", ":hover": { bgcolor: "#3f8f42" } }}
                >
                  Simular economia
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Grid bgcolor={"#112A3A"} px={4}>
          <Grid py={8}>
            <Typography
              textAlign={"center"}
              level="h1"
              fontSize={48}
              fontWeight={700}
              sx={{
                color: "#FFFFFF",
                "@media (max-width: 400px)": {
                  fontSize: 40,
                },
              }}
            >
              Equipamentos
            </Typography>
            <Typography
              textAlign={"center"}
              mb={8}
              mt={2}
              sx={{ color: "#4caf50" }}
            >
              Logo abaixo, confira a lista de equipamentos que trabalhamos
            </Typography>
            <Swiper
              slidesPerView={4}
              spaceBetween={28}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                500: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide>
                  <CardProduct
                    nameProduct={product.name}
                    imageCard={product.img}
                  ></CardProduct>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
        <Services></Services>
        <Grid bgcolor={"#0e2330"} px={2}>
          <Grid maxWidth={1280} m={"auto"} py={8} textAlign={"center"}>
            <Typography
              fontSize={48}
              fontWeight={700}
              mb={2}
              sx={{
                color: "#FFFFFF",
                "@media (max-width: 400px)": {
                  fontSize: 40,
                },
              }}
            >
              Projetos
            </Typography>
            <Typography mb={8} sx={{ color: "#4caf50" }}>
              Confirar os projetos realizados para nossos clientes
            </Typography>

            <Carousel
              setImages={getImagesSlide}
              modalIsOpen={setModalIsOpen}
            ></Carousel>
          </Grid>
        </Grid>
        <Grid bgcolor={"#112A3A"} px={2}>
          <Grid maxWidth={1280} textAlign={'center'} m={"auto"} py={8}>
           <Grid maxWidth={600} m={'auto'}>
           <Typography
              mb={2}
              fontSize={48}
              fontWeight={700}
              sx={{ color: "#ffffff" }}
            >
              Depoimentos
            </Typography>
            
            <Typography mb={8} sx={{ color: "#4caf50" }}>
              Depoimentos de Clientes que Iluminam suas Vidas e o Futuro
              Sustentável.
            </Typography>
           </Grid>
            <Testimonial></Testimonial>
          </Grid>
        </Grid>
      </Box>
      <Footer></Footer>
    </div>
  );
};

export default Home;
