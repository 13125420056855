import { Box, Divider, Link, List, ListItem, Typography } from "@mui/joy";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { Link as LinkRoute, useNavigate } from "react-router-dom";

const Footer = () => {
  return (
    <Grid bgcolor={"#0e2330"} px={8}>
      <Grid maxWidth={1280} m={"auto"}>
        <Stack
          py={4}
          m={"auto"}
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            "@media (max-width: 1200px)": {
              flexDirection: 'column-reverse',
              justifyContent: 'flex-start',
              alignItems: 'start',
              gap: 2
            },
          }}
        >
          <Box>
            <List orientation="horizontal" sx={{ color: "#FFFFFF", p: 0, flexWrap: 'wrap', gap: 2 }}>
              <ListItem sx={{color:'#ffffff', p: 0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/sobre-nos'}>Sobre nos</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/como-funciona'}>Como funciona</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/servicos'}>Serviços</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/projetos'}>Projetos</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/faq'}>FAQ</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/simulador-de-economia'}>Simulador de economia</Link></ListItem>
              <ListItem sx={{color:'#ffffff', p:0}}><Link sx={{color:'#ffffff8f'}} component={LinkRoute} to={'/contato'}>Contato</Link></ListItem>
            </List>
          </Box>
          <Link component={LinkRoute} to={'/'}>
            <img width={96} src="./logo.svg" alt="" />
          </Link>
        </Stack>
        <Divider></Divider>
        <Stack
          py={4}
          m={"auto"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box textAlign={'center'} width={'100%'}>
            <Typography  fontSize="sm" sx={{color:'#ffffff8f', textAlign:"center"}} >
            © 2024 Comercial Solar. Todos os direitos reservados.
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
