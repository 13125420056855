import { AspectRatio, Card, CardOverflow, Typography } from "@mui/joy";
import React from "react";

const CardComponent = (props: any) => {
  return (
    <Card  sx={{bgcolor:'#0e2330', border: 0, boxShadow: '0px 3px 14px -3px rgba(0,0,0,0.75);', cursor: 'pointer', transitionDuration:0.5, msTransitionTimingFunction: 'ease', borderBottom: 2, borderColor: 'transparent', boxSizing: 'border-box', mb: 2, ":hover":{borderBottom: 2, borderColor: '#4caf50', }}}>
      <CardOverflow>
      <AspectRatio ratio={1}>
        <img
          src={props.setImageCliente}
          className="md:max-h-48 max-h-80 w-full h-full object-cover"
          alt={`Images da casa do cliente que foi feito a instalçao de painel solar - ${props.setNomeCliente}`}
        />
      </AspectRatio>
      </CardOverflow>
      

      <div className="absolute group-hover:opacity-100 w-full opacity-0  h-full bg-black/75 top-0 duration-500  flex items-center justify-center ">
        <Typography sx={{color: '#ffffff'}} fontWeight={700}>{props.setNomeCliente}</Typography>
      </div>
    </Card>
  );
};

export default CardComponent;
