import axios from 'axios';



export async function getCounty(sigla: string){
    try{
        const  res = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios`)
        console.log(res.data)
        if(res.status === 200){
            console.log(res.data)
            return res.data
        }
        return []
    }
    catch(error){
        return []
    }
}