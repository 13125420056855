import { Box, Button, Grid, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { Header } from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Testimonial } from "../components/Testimonials";


export function Projetos() {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const getImagesSlide = (data: any) => {
    setImages(data);
  };
  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modalIsOpen]);
  return (
    <Box>
        <Header></Header>
        <Lightbox
        open={modalIsOpen}
        plugins={[Fullscreen]}
        close={() => setModalIsOpen(false)}
        slides={images}
      />
        <Grid bgcolor={"#0e2330"} px={2}>
      <Grid maxWidth={1280} m={"auto"} py={8} textAlign={"center"}>
        <Typography
          fontSize={48}
          fontWeight={700}
          mb={2}
          sx={{
            color: "#FFFFFF",
            "@media (max-width: 400px)": {
              fontSize: 40,
            },
          }}
        >
          Projetos
        </Typography>
        <Typography mb={8} sx={{ color: "#ffffffc5" }}>
          Confirar os projetos realizados para nossos clientes
        </Typography>

        <Carousel
          setImages={getImagesSlide}
          modalIsOpen={setModalIsOpen}
        ></Carousel>
      </Grid>
    </Grid>
    <Grid>
        <Stack
        height={400}
        
          sx={{
            width: "100%",
            backgroundImage: `url(${require('../images/bg-energia-solar-home.jpeg')})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `cover`,
            backgroundPosition: `left bottom`,
          }}
        >
          <Grid
            px={2}
            py={8}
            display={'flex'}
        justifyContent={'center'}
            height={'100%'}
            sx={{
              "@media (max-width: 1200px)": {
                py: 6,
              },
              background:
                " linear-gradient(63deg, rgba(3,28,56,0.5074404761904762) 13%, rgba(3,28,56,0.9780287114845938) 71%);",
            }}
          >
            <Grid container maxWidth={768}  m={"auto"}>
             
              <Grid
                width={"100%"}
                sx={{
                  "@media (max-width: 1200px)": {
                    ml: 0,
                  },
                }}
                flex={1}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography level="h1" mb={4} sx={{ color: "#ffffff" }} textAlign={'center'}>
                Transforme a luz do sol em economia
                </Typography>
                <Typography mb={4} sx={{ color: "#ffffff" }} textAlign={'center'}>
                Solicite seu orçamento de energia solar hoje mesmo!
                </Typography>
               <Grid width={'100%'} display={'flex'} justifyContent={'center'}>
               <Button
                  size="lg"
                  sx={{ bgcolor: "#4caf50", m: 'auto',  ":hover": { bgcolor: "#3f8f42" }, "@media (max-width: 600px)": {
                    width: '100%',
                    
                  }, }}
                >
                  Solicitar orçamento
                </Button>
               </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        </Grid>
        <Grid px={2}>
          <Grid maxWidth={1280}  py={8} m={'auto'}>
            <Testimonial></Testimonial>
          </Grid>
        </Grid>
        <Footer></Footer>
    </Box>
    
  );
}
