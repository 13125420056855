import { Avatar, Grid, Typography } from '@mui/joy';
import React from 'react';


export function Services(){
    return (
        <Grid bgcolor={'#112A3A'} px={2}>
          <Grid maxWidth={1280} m={'auto'} py={8}>
          <Typography
                  fontSize={48}
                  mb={2}
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#ffffff", "@media (max-width: 400px)": {
                    fontSize: 40
                  }, }}
                >
                  Serviços
                </Typography>
                <Typography
                mb={8}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Oferecemos os melhores serviços do mercado
                </Typography>
            <Grid
            sx={{"@media (max-width: 900px)": {
              gridTemplateColumns: 'repeat(2, 1fr)'
              },
              "@media (max-width: 500px)": {
                gridTemplateColumns: 'repeat(1, 1fr)'
                },
            }}
            display={'grid'}
              gridTemplateColumns={'repeat(4, 1fr)'}
              gap={8}
              direction="row"
              
              m={"auto"}
              container
              position={"relative"}
            >
              <Grid   borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    mx: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 120,
                    width: 120,
                    p: 4
                  }}
                  size="lg"
                >
                  <img src="./instalacao.svg" alt="" />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Instalação
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#F1F1F1" }}
                >
                 Nossa equipe de instalação segue normas técnicas, garantindo segurança e preservação das características originais com alto padrão de qualidade.
                </Typography>
              </Grid>
              <Grid   borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 120,
                    width: 120,
                    p: 4
                  }}
                  size="lg"
                >
                  <img src="./HOMOLOÇAO.svg" alt="" />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Homologação
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#F1F1F1" }}
                >
                  Após a instalação concluída e os testes realizados, solicitamos a troca do medidor para um medidor bidirecional junto à concessionária.
                </Typography>
              </Grid>
              <Grid   borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 120,
                    width: 120,
                    p: 4
                  }}
                  size="lg"
                >
                  <img src="./PROJETO.svg" alt="" />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Projetos​
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#F1F1F1" }}
                >
                  Estudar as características locais da propriedade é crucial para a instalação eficiente do sistema fotovoltaico, incluindo sombreamentos e orientação geográfica da construção. 
                </Typography>
              </Grid>
              <Grid  borderRadius={2} gap={2}>
                <Avatar
                  sx={{
                    m: "auto",
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 120,
                    width: 120,
                    p: 4
                  }}
                  size="lg"
                >
                    <img src="./VENDAS.svg" alt="" />
                </Avatar>
                <Typography
                  my={2}
                  level="h3"
                  fontWeight={700}
                  textAlign={"center"}
                  sx={{ color: "#4caf50" }}
                >
                  Vendas
                </Typography>
                <Typography
                  fontSize={"sm"}
                  sx={{ color: "#F1F1F1" }}
                >
                  A equipe de vendas realiza um estudo minucioso no consumo de energia elétrica visando sempre o melhor custo benefício para o cliente, tanto imediato e com a possibilidade de ampliação futura
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    )
}

