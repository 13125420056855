import { FavoriteBorder } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/joy";
import React from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export function Testimonial() {
  const persons = [
    {
      name: "Thiago Enrico - Salvador/BA",
      text: "A energia solar mudou a minha vida. Eu sempre quis ter um sistema fotovoltaico na minha casa, mas achava que era muito caro e complicado. Mas depois que conheci a empresa, vi que era possível e acessível. Eles me explicaram tudo sobre o funcionamento, os benefícios e os custos do sistema. Eles instalaram tudo em um dia e me deram todo o suporte necessário. Agora eu tenho uma fonte de energia limpa, renovável e econômica. Estou muito feliz com a minha escolha e agradeço à empresa pelo ótimo trabalho.",
    },
    {
      name: "Renan Pinto - Camaçari/BA",
      text: "Eu recomendo a empresa para quem quer ter energia solar na sua casa ou no seu negócio. Eles são muito competentes e honestos. Eles me deram um orçamento justo e sem surpresas. Eles fizeram a instalação do sistema fotovoltaico com cuidado e capricho. Eles também me deram todas as informações e dicas sobre como aproveitar ao máximo a energia solar. Eu estou muito satisfeito com o serviço e com o produto. A energia solar é um investimento que vale a pena.",
    },
    {
      name: "Mayane Mourão - Lauro de Freitas/BA",
      text: "Eu sempre quis ter energia solar na minha casa, mas tinha medo de ser enganada ou de ter problemas com o sistema. Mas depois que eu conheci a empresa, eu mudei de ideia. Eles me esclareceram todas as dúvidas e me mostraram os benefícios da energia solar. Eles me ofereceram um preço justo e um financiamento facilitado.",
    },
    {
      name: "José Antônio - Salvador/BA",
      text: "Fiquei muito impressionado com o atendimento da empresa. Eles foram muito prestativos e cordiais desde o primeiro contato até a conclusão dos serviços. Todos os funcionários são muito educados e profissionais. O sistema foi instalado no tempo combinado e está funcionando perfeitamente, superando as nossas expectativas.",
    },
  ];
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={18}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {persons.map((person) =>(
        <SwiperSlide >
        <Card
          sx={{
            minHeight: 420,
            display: 'flex',
            width: "100%",
            maxWidth: "100%",
            boxShadow: "lg",
            bgcolor: "#0e2330",
            borderColor: "#091820",
            p: 4,
          }}
        >
          <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
            <Avatar
              src="/static/images/avatar/1.jpg"
              sx={{ "--Avatar-size": "4rem" }}
            />
            <Typography level="title-lg" sx={{ color: "#FFFFFF" }} my={1}>
              {person.name}
            </Typography>
            <Typography
              level="body-sm"
              textAlign={"left"}
              sx={{ color: "#FFFFFF" }}
            >
              {person.text}
            </Typography>
          </CardContent>
        </Card>
      </SwiperSlide>
      ))}
      
    </Swiper>
  );
}
