import { Navigate, Route, RouteProps, useNavigate, redirect } from 'react-router-dom';


const PrivateRoute = ({children, redirect} :any, ) => {
    const navigate = useNavigate()
    if(sessionStorage.getItem('simulation')){
        console.log('ok')
        return children
    }
    else{
        return <Navigate to={redirect}></Navigate>
    }
};

export default PrivateRoute;
