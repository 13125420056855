import React, { useState, useEffect } from "react";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { getCounty } from "../services/ibgeApi";
import Autocomplete from "@mui/joy/Autocomplete";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import Button from "@mui/joy/Button";
import { IMaskInput } from "react-imask";
import validator from "validator";
import FormHelperText from "@mui/joy/FormHelperText/FormHelperText";
import { InfoOutlined } from "@mui/icons-material";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/layout/Header";
import { createQuotes } from "../services/solarAPI";
import {
  AspectRatio,
  Box,
  Grid,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
  stepClasses,
  stepIndicatorClasses,
  typographyClasses,
} from "@mui/joy";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const TextMaskAdapter = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(00) 0 0000-0000"
        placeholder="(12) 3 4567-8910"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        unmask={true}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const TextMaskAdapterMoney = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        scale={2} // Duas casas decimais
        thousandsSeparator="."
        radix=","
        mapToRadix={["."]}
        inputRef={ref}
        unmask={true}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);
const SolicitarOrcamento = () => {
  const [loading, setLoading] = useState(false)
  const [estado, setEstado] = useState("");
  const [county, setCounty] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isMinimalValue, setIsMinimalValue] = useState(false);
  const [accountValue, setAccountValue] = useState("");
  const [cpfOrCNPJ, setCpfOrCNPJ] = useState("CPF");
  const [countyList, setCountyList] = useState<any[]>([]);
  const [typeInstallation, setTypeInstallation] = useState("");
  const navigate = useNavigate();
  const icons = ["/casa.svg", "/empresa.svg", "/fazenda.svg", "/industria.svg"];
  const [enabledSubmit, setEnabledSubmit] = useState(true);
  const handleSubmit = async () => {
    setLoading(true)
    const data = await createQuotes(
      name,
      email,
      phone,
      typeInstallation,
      estado,
      county,
      false,
      parseFloat(accountValue)
    );
    setLoading(false)
    // setTimeout(() => {
    //   navigate("/orcamento-solicitado");
    // }, 600);
  };
  const handleChange = async (value: any) => {
    setEstado(value?.sigla);
    const data: string[] = await getCounty(value?.sigla);
    console.log(data);
    if (data) {
      setCountyList(data);
    }
  };

  useEffect(() => {
    if (
      typeInstallation !== "" &&
      estado &&
      validator.isEmail(email) &&
      name &&
      phone &&
      county &&
      !validator.isEmpty(accountValue, { ignore_whitespace: true }) &&
      !isMinimalValue
    ) {
      setEnabledSubmit(false);
    } else {
      setEnabledSubmit(true);
    }
  }, [
    estado,
    phone,
    typeInstallation,
    email,
    county,
    name,
    cpfOrCNPJ,
    accountValue,
    isMinimalValue,
  ]);

  return (
    <Grid
      sx={{
        "@media (max-width: 1024px)": {
          height: "auto",
        },
      }}
    >
      <Header headerCenter={true}></Header>
      <Grid
        maxWidth={900}
        m={"auto"}
        px={2}
        py={4}
        sx={{
         
        }}
      >
        <Typography
          fontSize={48}
          fontWeight={700}
          sx={{ color: "#FFFFFF" }}
          my={1}
          textAlign={"center"}
        >
          Solicite seu orçamento
        </Typography>
        <Grid maxWidth={768} m={"auto"} mt={2} mb={4}>
          <Typography sx={{ color: "#FFFFFF" }}  my={1} textAlign={"center"}>
            Preencha os dados abaixo para que um de nossos consultores entre em
            contato com você.
          </Typography>
        </Grid>
        <Grid
          container
          position={"relative"}
          sx={{
            "@media (max-width: 1024px)": {
              maxWidth: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Grid
            sx={{
              "@media (max-width: 1280px)": {
                minWidth: "500px",
                width: "100%",
              },
              "@media (max-width: 1024px)": {
                maxWidth: "100%",
                width: "100%",
                minWidth: "100%",
              },
            }}
            flex={1}
            borderRadius={6}
            m={"auto"}
            display={"flex"}
            flexDirection={"column"}
            gap={4}
          >
            <Grid>
              <Typography level="title-lg" sx={{ color: "#FFFFFF" }} my={1}>
                Tipo de instalaçao
              </Typography>
              <RadioGroup
                aria-label="platform"
                defaultValue="Website"
                overlay
                name="platform"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  "@media (max-width: 1060px)": {
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  },
                  gap: 2,
                  [`& .${radioClasses.checked}`]: {
                    [`& .${radioClasses.action}`]: {
                      inset: -1,
                      border: "3px solid",
                      borderColor: "#4caf50",
                    },
                  },
                  [`& .${radioClasses.radio}`]: {
                    display: "contents",
                    "& > svg": {
                      zIndex: 2,
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      bgcolor: "background.surface",
                      borderRadius: "50%",
                    },
                  },
                }}
              >
                {[
                  { label: "RESIDÊNCIA", type: "residencia" },
                  { label: "EMPRESA", type: "empresa" },
                  { label: "AGRONEGÓCIO", type: "agronegocio" },
                  { label: "INDÚSTRIA", type: "industria" },
                ].map((value, index) => (
                  <Sheet
                    key={value.type}
                    variant="outlined"
                    sx={{
                      borderRadius: "md",
                      boxShadow: "sm",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1.5,
                      p: 2,
                      width: "100%",
                      backgroundColor: "#112A3A",
                      borderColor: "#15455e",
                    }}
                  >
                    <Radio
                      id={value.type}
                      value={value.type}
                      onChange={() => setTypeInstallation(value.type)}
                      checkedIcon={
                        <CheckCircleRoundedIcon sx={{ color: "#4caf50" }} />
                      }
                    />
                    <AspectRatio
                      ratio="1"
                      sx={{
                        width: 42,
                        height: 42,
                        "@media (max-width: 1060px)": {
                          width: 48,
                          height: 48,
                        },
                      }}
                      variant="plain"
                    >
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={icons[index]}
                        alt=""
                      />
                    </AspectRatio>

                    <FormLabel
                      sx={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        "@media (max-width: 1060px)": {
                          fontSize: 10,
                        },
                      }}
                      htmlFor={value.label}
                    >
                      {value.label}
                    </FormLabel>
                  </Sheet>
                ))}
              </RadioGroup>
            </Grid>
            <Grid
              alignSelf={"flex-start"}
              width="100%"
              gap={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <FormControl sx={{ width: "100%" }}>
                <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                  Nome
                </FormLabel>
                <Input
                  sx={{
                    bgcolor: "#112A3A",
                    color: "#ffffff",
                    borderColor: "#15455e",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="lg"
                  fullWidth
                  placeholder="Digite seu nome completo"
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                  E-mail
                </FormLabel>
                <Input
                  sx={{
                    bgcolor: "#112A3A",
                    color: "#ffffff",
                    borderColor: "#15455e",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="lg"
                  fullWidth
                  placeholder="exemplo@email.com"
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                  Celular
                </FormLabel>
                <Input
                  sx={{
                    bgcolor: "#112A3A",
                    color: "#ffffff",
                    borderColor: "#15455e",
                  }}
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder="Placeholder"
                  slotProps={{ input: { component: TextMaskAdapter } }}
                />
              </FormControl>
              <Stack  direction={"row"} sx={{
                "@media (max-width: 600px)": {
                  flexDirection: 'column' 
                },
              }} gap={2}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                    Estado
                  </FormLabel>
                  <Autocomplete
                    sx={{
                      bgcolor: "#112A3A",
                      color: "#ffffff",
                      borderColor: "#15455e",
                    }}
                    size="lg"
                    options={[{ nome: "Bahia", sigla: "BA" }]}
                    onChange={(event, value) => handleChange(value)}
                    placeholder="Estado"
                    getOptionLabel={(option) => option.nome}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                    Cidade
                  </FormLabel>
                  <Autocomplete
                    sx={{
                      bgcolor: "#112A3A",
                      color: "#ffffff",
                      borderColor: "#15455e",
                    }}
                    disabled={countyList.length > 0 ? false : true}
                    size="lg"
                    options={countyList}
                    placeholder="Cidade"
                    onChange={(e, value) => {
                      setCounty(value.nome);
                      console.log(value);
                    }}
                    getOptionLabel={(option) => option.nome}
                  />
                </FormControl>
              </Stack>

              <FormControl error={isMinimalValue}>
                <FormLabel sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                  Valor da conta de luz
                </FormLabel>
                <Input
                  sx={{
                    bgcolor: "#112A3A",
                    color: "#ffffff",
                    borderColor: "#15455e",
                  }}
                  value={accountValue}
                  startDecorator={<p>R$</p>}
                  onChange={(e) => setAccountValue(e.target.value)}
                  size="lg"
                  fullWidth
                  slotProps={{ input: { component: TextMaskAdapterMoney } }}
                  onBlur={() => {
                    console.log("entrou");
                    if (parseFloat(accountValue) < 300 || !accountValue) {
                      setIsMinimalValue(true);
                    } else {
                      setIsMinimalValue(false);
                    }
                  }}
                />
                {isMinimalValue && (
                  <FormHelperText>
                    <InfoOutlined />
                    {validator.isEmpty(accountValue, {
                      ignore_whitespace: true,
                    }) ? (
                      <p>Campo obrigatório</p>
                    ) : (
                      <p>Valor minimo é de 300 reais</p>
                    )}
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                loading={loading}
                
                loadingPosition="start"
                disabled={enabledSubmit}
                onClick={handleSubmit}
                sx={{
                  mt: 4,
                  ":disabled": {
                    backgroundColor: "#4caf50",
                    color: "#fff",
                    opacity: 0.5,
                  },
                  backgroundColor: "#4caf50",
                  ":hover": { backgroundColor: "rgba(66, 160, 69, 0.918)" },
                }}
                size="lg"
              >
                Solicitar orçamento
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SolicitarOrcamento;
