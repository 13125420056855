import React from "react";
import { Header } from "../components/layout/Header";
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { Avatar, Button } from "@mui/joy";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CardProduct } from "../components/CardProducts";
import { KeyboardArrowRight } from "@mui/icons-material";
import Footer from "../components/layout/Footer";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";



export function HowItWorks() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Header></Header>
      <Grid>
        <Grid>
          <Grid maxWidth={1280} m={"auto"} py={8} px={4}>
            <Grid textAlign={"center"} mb={8}>
              <Typography
                level="h1"
                fontSize={48}
                fontWeight={700}
                sx={{
                  color: "#FFFFFF",
                  "@media (max-width: 400px)": {
                    fontSize: 40,
                  },
                }}
              >
                Veja como funciona
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              sx={{
                "@media (max-width: 1024px)": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                flex={1}
                mr={12}
                sx={{
                  "@media (max-width: 1024px)": {
                    mr: 0,
                    mb: 12,
                  },
                }}
              >
                <Grid gap={2} display="flex" flexDirection="column">
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      size="lg"
                      sx={{ bgcolor: "#4caf50", color: "#ffffff" }}
                    >
                      1
                    </Avatar>
                    <Typography sx={{ color: "#f6f6f6" }}>
                      Painel Solar - Painéis solares transformam a luz do sol em
                      eletricidade.
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      size="lg"
                      sx={{ bgcolor: "#4caf50", color: "#ffffff" }}
                    >
                      2
                    </Avatar>
                    <Typography sx={{ color: "#f6f6f6" }}>
                      O inversor transforma a energia solar dos painéis em
                      eletricidade para o uso doméstico.
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      size="lg"
                      sx={{ bgcolor: "#4caf50", color: "#ffffff" }}
                    >
                      3
                    </Avatar>
                    <Typography sx={{ color: "#f6f6f6" }}>
                      O quadro elétrico distribui a eletricidade pela casa,
                      ligando e desligando diferentes áreas para garantir que
                      tudo funcione corretamente e com segurança.
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      size="lg"
                      sx={{ bgcolor: "#4caf50", color: "#ffffff" }}
                    >
                      4
                    </Avatar>
                    <Typography sx={{ color: "#f6f6f6" }}>
                      O medidor de energia solar mede o quanto você consome da
                      rede elétrica e o quanto seus painéis solares geram.
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Avatar
                      size="lg"
                      sx={{ bgcolor: "#4caf50", color: "#ffffff" }}
                    >
                      5
                    </Avatar>
                    <Typography sx={{ color: "#f6f6f6" }}>
                      A energia excedente é direcionada de volta à rede da
                      distribuidora, gerando créditos para o consumidor.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid flex={1}>
                <img src="./diagrama-energy.svg" alt="" />
              </Grid>
            </Grid>
            <Grid
              width={"100%"}
              m={"auto"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Button
                onClick={() => navigate("/solicitar-orcamento")}
                sx={{
                  borderColor: "#4caf50",
                  color: "#4caf50",
                  mt: 8,
                  ":hover": { backgroundColor: "#4caf50", color: "#ffffff" },
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
                variant="outlined"
                size="lg"
                endDecorator={<KeyboardArrowRight />}
              >
                SOLICITAR ORÇAMENTO
              </Button>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid width={"100%"} px={4} bgcolor={"#112A3A"}>
          <Grid maxWidth={1280} m={"auto"} py={8}>
            <Grid
              mb={8}
              display={"grid"}
              sx={{
                gridTemplateColumns: "repeat(3, 1fr)",
                "@media (max-width: 768px)": {
                  gridTemplateColumns: "repeat(1, 1fr)",
                },
              }}
              gap={2}
              position={"relative"}
            >
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  1
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Consulta gratuita
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  O primeiro passo para instalar energia solar é agendar uma
                  consulta gratuita com nossa equipe de especialistas. Durante
                  esta consulta, discutiremos suas necessidades específicas e
                  determinaremos o tamanho e o tipo de sistema mais adequados
                  para você.
                </Typography>
              </Stack>
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  2
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Elaboração de proposta
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  Após a consulta, elaboraremos uma proposta personalizada com
                  base em suas necessidades e preferências. A proposta incluirá
                  informações sobre o tamanho e o tipo do sistema, bem como uma
                  estimativa de custos e economias potenciais.
                </Typography>
              </Stack>
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  3
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Fechar negócio
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  Após revisar e aprovar a proposta, você pode fechar negócio
                  com nossa equipe. Trabalharemos com você para agendar uma data
                  de instalação conveniente e determinaremos os próximos passos
                  para o processo.
                </Typography>
              </Stack>
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  4
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Documentação
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  Antes da instalação, será necessário concluir a documentação
                  necessária para obter as autorizações e permissões necessárias
                  para instalar o sistema. Nosso time cuidará dessa etapa para
                  você, garantindo que todo o processo seja transparente e sem
                  estresse.
                </Typography>
              </Stack>
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  5
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Instalação do sistema
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  Uma vez que toda a documentação estiver pronta e as
                  autorizações necessárias forem obtidas, nossa equipe de
                  técnicos qualificados iniciará a instalação do seu sistema no
                  local planejado.
                </Typography>
              </Stack>
              <Stack
                border={1}
                borderColor={"#083466"}
                p={4}
                borderRadius={6}
                gap={2}
              >
                <Avatar
                  sx={{
                    borderTop: 2,
                    borderColor: "#4caf50",
                    bgcolor: "transparent",
                    fontSize: 38,
                    color: "#4caf50",
                    height: 60,
                    width: 60,
                  }}
                  size="lg"
                >
                  6
                </Avatar>
                <Typography
                  level="h3"
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  Ativação do sistema
                </Typography>
                <Typography
                  level="h3"
                  fontSize={"sm"}
                  sx={{ color: "#FFFFFF" }}
                >
                  Após a instalação, solicitamos à distribuidora local a troca
                  do medidor por um medidor bidirecional. Após a troca, o
                  sistema começa a gerar energia.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              width={"100%"}
              m={"auto"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Button
                onClick={() => navigate("/solicitar-orcamento")}
                sx={{
                  borderColor: "#4caf50",
                  color: "#4caf50",
                  ":hover": { backgroundColor: "#4caf50", color: "#ffffff" },
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
                variant="outlined"
                size="lg"
                endDecorator={<KeyboardArrowRight />}
              >
                SOLICITAR ORÇAMENTO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
