import { AspectRatio, Button, Grid, Stack, Typography } from "@mui/joy";
import React from "react";
import { Testimonial } from "../components/Testimonials";
import { Services } from "../components/Services";
import { Header } from "../components/layout/Header";
import Footer from "../components/layout/Footer";

export function ServicesPage() {
  return (
    <React.Fragment>
        <Header></Header>
      <Grid >
        <Grid
           
          display={"flex"}
          m={"auto"}
          flexDirection={"column"}
          gap={10}
        >
          <Services></Services>
        </Grid>
        <Grid>
        <Stack
          sx={{
            width: "100%",
            backgroundImage: "url(./casa-energia-solar.jpeg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: `cover`,
            backgroundPosition: `left top`,
          }}
        >
          <Grid
            px={2}
            py={8}
            sx={{
              "@media (max-width: 1200px)": {
                py: 6,
              },
              background:
                " linear-gradient(63deg, rgba(3,28,56,0.5074404761904762) 13%, rgba(3,28,56,0.9780287114845938) 71%);",
            }}
          >
            <Grid container maxWidth={600}  m={"auto"}>
             
              <Grid
                width={"100%"}
                sx={{
                  "@media (max-width: 1200px)": {
                    ml: 0,
                  },
                }}
                flex={1}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography level="h1" mb={4} sx={{ color: "#ffffff" }}>
                  A energia solar é a chave para um futuro sustentável
                </Typography>
                <Typography mb={4} sx={{ color: "#ffffff" }}>
                  Orçamento feito na medida para você! Economia de até 95% na
                  sua conta de luz e impacto positivo para todo mundo.
                </Typography>
               <Grid width={'100%'} display={'flex'} justifyContent={'center'}>
               <Button
                  size="lg"
                  sx={{ bgcolor: "#4caf50", m: 'auto',  ":hover": { bgcolor: "#3f8f42" }, "@media (max-width: 600px)": {
                    width: '100%',
                    
                  }, }}
                >
                  Simular economia
                </Button>
               </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        </Grid>
        <Grid px={2}>
          <Grid maxWidth={1280}  py={8} m={'auto'}>
            <Testimonial></Testimonial>
          </Grid>
        </Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
