import React, { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "../../Drawer";
import { Link as LinkRoute, useNavigate } from "react-router-dom";
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/joy";
export const Header = (props: any) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };
  if (props.headerCenter) {
    return (
      <Grid height={64} py={1}>
        <Grid m={"auto"}>
          <Link
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            href="/"
          >
            <img width={96} src="/logo.svg" alt="" />
          </Link>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box sx={{ width: "100%", bgcolor: "#0e2330" }}>
      <Stack
        px={{ lg: 6, xl: 0, xs: 6 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingY={3}
        marginX="auto"
        maxWidth="1280px"
      >
        <Box display="flex">
          <Link component={LinkRoute} to="/">
            <img style={{ width: "96px" }} src="/logo.svg" alt="" />
          </Link>
        </Box>
        <List
          sx={{
            color: "#FFFFFF",
            my: "auto",
            gap: 4,
            justifyContent: "center",
            "@media (max-width: 1200px)": {
              display: 'none'
            },
          }}
          orientation="horizontal"
        >
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/"
          >
            Inicio
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/sobre-nos"
          >
            Quem somos
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/como-funciona"
          >
            Como funciona
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/servicos"
          >
            Serviços
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/faq"
          >
            FAQ
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/projetos"
          >
            Projetos
          </Link>
          <Link
            sx={{ color: "#ffffff", fontWeight: 700, ":hover": {color: '#4CAF50', textDecoration: 'none'},  }}
            component={LinkRoute}
            to="/contato"
          >
            Contato
          </Link>
        </List>
        <Box flexDirection="row" display="flex" alignItems="center" gap={6}>
          <Button
          onClick={()=>{navigate('/solicitar-orcamento')}}
            variant="outlined"
            sx={{
              "@media (max-width: 600px)": {
                display: "none",
              },
              borderRadius: "lg",
              borderColor: "#4CAF50",
              color: "#4CAF50",
              ":hover": { backgroundColor: "#4CAF50", color: "#FFFFFF" },
            }}
            size="lg"
          >
            Orçamento
          </Button>
          <Drawer></Drawer>
        </Box>
      </Stack>

      {props.headerBanner && (
        <Box
          height={600}
          sx={{
            "@media (max-width: 1200px)": {
              height: '100vh'
            },
          }}
        >
          <Stack
            height={"100%"}
            gap={6}
            px={{ lg: 6, xl: 0, xs: 6 }}
            sx={{ position: "relative", "@media (max-width: 1200px)": {
              px: 2
            }, } }
            maxWidth="1280px"
            m="auto"
            direction={{ lg: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Stack direction="column" gap={4} maxWidth={600} sx={{
              "@media (max-width: 1200px)": {
                maxWidth: '100%',
                mt: 6
              },
            }}>
              <Typography
                noWrap={false}
                level="h1"
                variant="plain"
                fontSize={{ md: 36, lg: 48, xl: 60, xs: 30 }}
                sx={{ color: "#FFFFFF", fontWeight: 400 }}
              >
                <Typography fontWeight={700}>ENERGIA</Typography> QUE NUNCA SE{" "}
                <Typography fontWeight={700}>ESGOTA</Typography>
              </Typography>
              <Typography
                noWrap={false}
                variant="plain"
                fontSize={{ xs: 14, md: 16, xl: 16 }}
                sx={{ color: "#F1F1F1" }}
              >
                Com ela, você economiza dinheiro, valoriza o seu imóvel e
                protege o planeta. E para ter acesso a essa energia incrível,
                basta solicitar um orçamento hoje mesmo.
              </Typography>
              <Box sx={{
                "@media (max-width: 1200px)": {
                  width: '100%'
                },
              }}>
                <Button
                  onClick={()=>{navigate('/solicitar-orcamento')}}
                  size="lg"
                  sx={{"@media (max-width: 1200px)": {
                    width: '100%'
                  }, bgcolor: "#4CAF50", ":hover": { bgcolor: "#3f8f42" } }}
                >
                  Solicitar orçamento
                </Button>
              </Box>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                "@media (max-width: 1200px)": {
                  position: "initial",
                  right: "auto",
                  ml: 0,
                },
                "@media (max-width: 600px)": {
                  width: 700
                },
                "@media (max-width: 400px)": {
                  width: 500
                },
                bottom: 0,
                right: -150,
                ml: 40,
                display: "flex",
              }}
              width={864}
            >
              <img width={"100%"} src="/homem-topo.png" alt="" />
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
