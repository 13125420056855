import React, { useState } from "react";
import { Header } from "../components/layout/Header";
import { Link } from "react-router-dom";
import { Divider, Grid, Stack, Typography } from "@mui/joy";
import HelpIcon from '@mui/icons-material/Help';
import LinkJoy from '@mui/joy/Link';
import Footer from "../components/layout/Footer";

const Faq = () => {
  // const [menuList, setMenuList] = useState([{title: "INSTALACÁO", active: false, questions: [
  //   {title: ''}
  // ]}, {title: "INSTALACÁO", active: false}, {title: "INSTALACÁO", active: false}]);
  // const [activeButton, setActiveButton] = useState('border-transparent')
  // const updateButton = (key:number)=>{
  //   const menuListCopy = [...menuList]
  //   menuListCopy[key] = {...menuListCopy[key], active: true}

  //   setMenuList(menuListCopy)
  // }

  const questions = [
    {
      title: "Como funciona energia solar?",
      answer:
        "A energia solar fotovoltaica é aquela na qual a energia produzida provém da luz solar e é transformada em eletricidade para usarmos no dia a dia. Para a geração de energia solar, são usados painéis fotovoltaicos que farão a transformação da luz e também inversores que farão a corrente elétrica ser adequada para o uso.",
    },
    {
      title: "Quais são os benefícios da energia solar?",
      answer:
        "Redução na conta de luz, Ajuda o meio ambiente, Valorização do imóvel e Longa durabilidade e baixa manutenção.",
    },
    {
      title: "Quais são os benefícios da energia solar?",
      answer:
        "Redução na conta de luz, Ajuda o meio ambiente, Valorização do imóvel e Longa durabilidade e baixa manutenção.",
    },
    {
      title: "Quais são os benefícios da energia solar?",
      answer:
        "Redução na conta de luz, Ajuda o meio ambiente, Valorização do imóvel e Longa durabilidade e baixa manutenção.",
    },
  ];
  return (
    <div className="w-full ">
      <Header></Header>
      
      <Grid maxWidth={1280} m={"auto"} py={8} >
      <Typography fontWeight={700} mb={2} fontSize={48} sx={{ color: "#ffffff" }}>
                Perguntas frequentes
              </Typography>
              <Divider sx={{mb: 6}}></Divider>
        <Grid display={"grid"} gridTemplateColumns={"repeat(2, 1fr)"} sx={{
           "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(1, 1fr)"
          },
        }} gap={6}>
          <Grid display={'flex'} flexDirection={'column'} gap={4}>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>} mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Como funciona energia solar?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                A energia solar fotovoltaica é aquela na qual a energia
                produzida provém da luz solar e é transformada em eletricidade
                para usarmos no dia a dia. Para a geração de energia solar, são
                usados painéis fotovoltaicos que farão a transformação da luz e
                também inversores que farão a corrente elétrica ser adequada
                para o uso.
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2}  fontWeight={700} sx={{ color: "#ffffff" }}>
                Quais são os benefícios da energia solar?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                Redução na conta de luz, Ajuda o meio ambiente, Valorização do
                imóvel e Longa durabilidade e baixa manutenção.
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Preciso de permissão para instalar paineis solares em minha
                propriedade?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                Sim, você deve pedir autorização e informar sobre o uso de
                energia solar para que não haja problemas futuros, como sendo
                acusado de furto...
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Como funciona o monitoramento e manutenção do sistema de energia
                solar após a instalação?
              </Typography>
              <Typography sx={{ color: "#ffffff8b" }}>
                Após a instalação do sistema, os técnicos da empresa de energia
                solar irão conectar o inversor à rede wi-fi do seu imóvel. A
                manutenção preditiva inclui inspeções visuais periódicas nas
                placas solares para verificar possíveis riscos, arranhões,
                fissuras, manchas ou rachaduras que possam comprometer a
                captação e geração de energia solar 1234. Além disso, é
                recomendável realizar a limpeza periódica das placas solares
                para garantir o melhor rendimento com a tecnologia, pois o
                acúmulo de sujeira sobre a superfície do painel limita a
                passagem da luz do sol e reduz a eficiência das células solares
                135. A frequência de limpeza depende da localização do painel
                solar e da quantidade de detritos acumulada na superfície.
              </Typography>
            </Stack>
          </Grid>
          <Grid  display={'flex'} flexDirection={'column'} gap={4}>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Como funciona energia solar?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                A energia solar fotovoltaica é aquela na qual a energia
                produzida provém da luz solar e é transformada em eletricidade
                para usarmos no dia a dia. Para a geração de energia solar, são
                usados painéis fotovoltaicos que farão a transformação da luz e
                também inversores que farão a corrente elétrica ser adequada
                para o uso.
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Como é feito a instalação de painéis solares em telhados ou
                terrenos?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                Cada instalçao tem seu suporte de fixação especifico definido
                por tipo telha, laje ou solo.
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                É possivel armazenar energia solar para uso durante a noite ou
                em dias nublados?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                Sim. Existem duas opções para fornecer energia elétrica durante
                a noite quando se utiliza um sistema de energia solar
                fotovoltaica: utilizar um banco de baterias para armazenar a
                energia gerada durante o dia ou conectar o sistema à rede
                distribuidora de energia elétrica. Quando o sistema é conectado
                à rede distribuidora, não é necessário o uso de baterias, pois a
                concessionária pode fornecer energia elétrica nos dias chuvosos
                e durante a noite.
              </Typography>
            </Stack>
            <Stack  alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700}  sx={{ color: "#ffffff" }}>
                Qual a vida útil dos painéis solares e como é feita a disposição
                adequada ao final da vida útil?
              </Typography>
              <Typography  sx={{ color: "#ffffff8b" }}>
                A vida útil de um painel solar é de 25 a 30 anos e esse é o
                prazo que as fabricantes costumam dar como garantia. No entanto,
                algumas pesquisas mostram que a durabilidade de um painel solar
                pode ultrapassar os 40 anos.
              </Typography>
            </Stack>
            <Stack alignSelf={"flex-start"}>
              <Typography startDecorator={<HelpIcon fontSize="small"></HelpIcon>}  mb={2} fontWeight={700} sx={{ color: "#ffffff" }}>
                Qual é o tamanho do sistema de energia solar que preciso para
                minha casa ou empresa?
              </Typography>
              <Typography sx={{ color: "#ffffff8b" }}>
                Para saber o tamanho do sistema de energia solar é necessário
                calcular a media de consumo anual de cada unidade consumidora,
                com essa media diemensionamos o sitema ideal para atender a
                necessidade especifca de cada cliente.
              </Typography>
              <LinkJoy sx={{color: '#4CAF50', textDecorationColor: '#4CAF50', mt: 2}} component={Link} to="/simulador-de-economia">Faça sua simulcaçao agora</LinkJoy>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Footer></Footer>
    </div>
  );
};

export default Faq;
