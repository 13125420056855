import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import Textarea from "@mui/joy/Textarea";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Header } from "../components/layout/Header";
import Footer from "../components/layout/Footer";

export function ContactPage() {
  const [text, setText] = useState("");
  return (
    <React.Fragment>
      <Header headerBanner={false} />
      <Box py={8} px={2} bgcolor={"#112A3A"}>
        

        <Grid display={'flex'} flexDirection={'row'} sx={{
          "@media (max-width: 968px)": {
            flexDirection: "column",
          },
        }}  maxWidth={1280} m={"auto"} gap={4} >
          <Grid

          >
            <Grid display={'flex'} flexDirection={"column"} alignSelf={'stretch'} maxWidth={1280} >
          <Box mb={6}>
          <Typography
              fontSize={24}
              sx={{ color: "#4CAF50" }}
            >
              Atendimento Comercial Solar
            </Typography>
            <Typography
              level="h1"
              fontSize={48}
              fontWeight={700}
              sx={{ color: "#FFFFFF" }}
            >
              Entre em contato conosco
            </Typography>
            <Link href="https://wa.me/71996902659"  sx={{width:'100%',color: '#ffffff', textDecoration: 'none'}}>
            <Button
            
            sx={{
              width:'100%',
              mt: 4,
              borderRadius: 6,
              bgcolor: "#4CAF50",
              ":hover": { bgcolor: "#43A647" },
            }}
            size="lg"
          >
            Entrar em contato pelo whatsapp
          </Button>
            </Link>
            
          </Box>
          <Box >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box
                width={60}
                minWidth={60}
                bgcolor={"#4CAF50"}
                borderRadius={6}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                height={60}
              >
                <LocalPhoneOutlinedIcon
                  fontSize="large"
                  sx={{ color: "#FFFFFF" }}
                ></LocalPhoneOutlinedIcon>
              </Box>
              <Box>
                <Typography
                  level="h2"
                  fontSize={24}
                  fontWeight={600}
                  sx={{ color: "#FFFFFF" }}
                >
                  Telefone de contato
                </Typography>
                <Typography
                  level="h4"
                  fontWeight={400}
                  sx={{ color: "#FFFFFF" }}
                >
                  (71) 99690-2659
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={2} my={4}>
              <Box
                width={60}
                bgcolor={"#4CAF50"}
                borderRadius={6}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                height={60}
              >
                <EmailOutlinedIcon
                  fontSize="large"
                  sx={{ color: "#FFFFFF" }}
                ></EmailOutlinedIcon>
              </Box>
              <Box>
                <Typography
                  level="h2"
                  fontSize={24}
                  fontWeight={600}
                  sx={{ color: "#FFFFFF" }}
                >
                  E-mail
                </Typography>
                <Typography
                  level="h4"
                  fontWeight={400}
                  sx={{ color: "#FFFFFF" }}
                >
                  jandsonleite@hotmail.com
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Box
                width={60}
                minWidth={60}
                bgcolor={"#4CAF50"}
                borderRadius={6}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                height={60}
              >
                <RoomOutlinedIcon
                  fontSize="large"
                  sx={{ color: "#FFFFFF" }}
                ></RoomOutlinedIcon>
              </Box>
              <Box>
                <Typography
                  level="h2"
                  fontSize={24}
                  fontWeight={600}
                  sx={{ color: "#FFFFFF" }}
                >
                  Endereço
                </Typography>
                <Typography
                  level="h4"
                  fontWeight={400}
                  sx={{ color: "#FFFFFF" }}
                >
                  Rua Lindolfo Barbosa, 328E, Vila Canária - Salvador, BA
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
          </Grid>
          <Stack
            flex={1}
            ml={6}
            sx={{
              "@media (max-width: 968px)": {
                ml: 0
                
              },
            }}

            borderRadius={6}
          >
            <Grid display={'flex'} flexDirection={'column'} gap={4} alignSelf={'stretch'} >
              {/* <Grid xs={1}>
                <Typography level="h3" fontWeight={500} mb={2}>
                Para nos enviar uma mensagem, preencha os campos no formulário de contato.
                </Typography>
              </Grid> */}
              <Grid xs={1}>
                <FormControl>
                  <FormLabel sx={{ color: "#FFFFFF" }}>Nome</FormLabel>
                  <Input
                    sx={{
                      bgcolor: "#0f2431",
                      color: "#FFFFFF",
                      borderColor: "#566a77",
                    }}
                    placeholder="Digite seu nome completo"
                    size="lg"
                  />
                </FormControl>
              </Grid>
              <Grid xs={1}>
                <FormControl>
                  <FormLabel sx={{ color: "#FFFFFF" }}>E-mail</FormLabel>
                  <Input
                    sx={{
                      bgcolor: "#0f2431",
                      color: "#FFFFFF",
                      borderColor: "#566a77",
                    }}
                    placeholder="Digite seu email"
                    size="lg"
                  />
                </FormControl>
              </Grid>
              <Grid xs={1}>
                <FormControl>
                  <FormLabel sx={{ color: "#FFFFFF" }}>Celular</FormLabel>
                  <Input
                    sx={{
                      bgcolor: "#0f2431",
                      color: "#FFFFFF",
                      borderColor: "#566a77",
                    }}
                    placeholder="Digite seu número de celular"
                    size="lg"
                  />
                </FormControl>
              </Grid>
              <Grid xs={1}>
                <FormControl>
                  <FormLabel sx={{ color: "#FFFFFF" }}>Assunto</FormLabel>
                  <Input
                    sx={{
                      bgcolor: "#0f2431",
                      color: "#FFFFFF",
                      borderColor: "#566a77",
                    }}
                    placeholder="Digite o assunto da mensagem"
                    size="lg"
                  />
                </FormControl>
              </Grid>
              <Grid xs={1}>
                <FormControl>
                  <FormLabel sx={{ color: "#FFFFFF" }}>Mensagem</FormLabel>
                  <Textarea
                    placeholder="Digite sua mensagem"
                    sx={{
                      bgcolor: "#0f2431",
                      color: "#FFFFFF",
                      borderColor: "#566a77",
                    }}
                    onChange={(event) => setText(event.target.value)}
                    minRows={6}
                    maxRows={6}
                    endDecorator={
                      <Typography level="body-xs" sx={{ ml: "auto" }}>
                        {text.length} character(s)
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid xs={1}>
                <FormControl>
                  <Button
                    sx={{
                      bgcolor: "#4CAF50",
                      ":hover": { bgcolor: "#43A647" },
                    }}
                    size="lg"
                  >
                    Enviar
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <Footer></Footer>
    </React.Fragment>
  );
}
