export const solarSavingsCalculator = (contaLuz: number) => {
  const tarifa = 0.744;
  const irradiacao = 5.42;

  const kwh = contaLuz / tarifa;

  const economiaMensal = contaLuz * 0.95;

  const potenciaKwp = kwh / (tarifa * 30 * irradiacao);

  const qtdPlacas = (potenciaKwp * 1000) / 555;

  const metrosQuadrados = 3.86 * qtdPlacas

  const estimativaInvestimento = potenciaKwp * 3800;

  const payback = estimativaInvestimento / contaLuz ;
  const economiaAnual = economiaMensal * 12;
  return {
    potenciaKwp,
    kwh,
    estimativaInvestimento,
    qtdPlacas,
    economiaMensal,
    payback,
    economiaAnual,
    metrosQuadrados
  };
};

const tarifas: Record<string,number> = {
    "residencia": 0.81,
    "empresa": 0.81,
    "industria": 0.49,
    "agronegocio": 0.49
}

const irradiacao = {
    estado: 'BA',
    valor: 122
}

type InstalacaoType = {
    residencia: string
    empresa: string
    industria: string
    agronegocio: string
}

    

export function calcular(valorConta:number, instalacaoTipo: string ) {
    // Tamanho do KWP
    const tarifa = tarifas[instalacaoTipo]
    let tamanhoKWP = valorConta / tarifa / irradiacao.valor;
   let geracaoMensalKWH = tamanhoKWP * irradiacao.valor
    let areaTelhado = (tamanhoKWP / 0.33) * 2.4
    // Preço por kWp
    console.log(tamanhoKWP+'')
    let precoKWP = 0.0;
    if (tamanhoKWP <= 2) {
        precoKWP = 4500;
    } else if (tamanhoKWP <= 4) {
        precoKWP = 4000;
    } else if (tamanhoKWP <= 6) {
        precoKWP = 3700;
    } else if (tamanhoKWP <= 14) {
        precoKWP = 3500;
    } else if (tamanhoKWP <= 28) {
        precoKWP = 3200;
    } else if (tamanhoKWP <= 40) {
        precoKWP = 2800;
    } else {
        precoKWP = 2200;
    }
    // Valor da conta mínima
    let valorContaMinima = 0.0;
    if (valorConta <= 500) {
        valorContaMinima = 50.0 * tarifa;
    } else {
        valorContaMinima = 100.0 * tarifa;
    }
    // Valor do investimento
    const  valorInvestimento = tamanhoKWP * precoKWP;
    const economiaMensal = valorConta - valorContaMinima;
    const economiaAnual = economiaMensal * 12

    const payback = (valorInvestimento / economiaAnual) *12
    // Valor fixo na aba "Premissas" da planilha
    
    return {
        tamanhoKWP: tamanhoKWP,
        valorInvestimento: valorInvestimento,
        precoKWP: precoKWP,
        geracaoMensalKWH: geracaoMensalKWH,
        areaTelhado: areaTelhado,
        economiaMensal: economiaMensal,
        economiaAnual: economiaAnual,
        payback: payback
    }}
