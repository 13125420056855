import React, { useEffect, useState } from "react";

import {
  Link,
  Navigate,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Add } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import {
  solarSavingsCalculator,
  calcular,
} from "../scripts/solarSavingsCalculator";
import axios from "axios";
import CircularProgress from "@mui/joy/CircularProgress";
import Modal from "@mui/joy/Modal";
import { config } from "process";
import { Header } from "../components/layout/Header";
import { Box, Divider, Grid, Stack, Typography } from "@mui/joy";

type CalculatorType = {
  tamanhoKWP: number;
  valorInvestimento: number;
  precoKWP: number;
  geracaoMensalKWH: number;
  areaTelhado: number;
  economiaMensal: number;
  economiaAnual: number;
  payback: number;
};

const ResultSimulator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [sessionStorageData, setSessionStorageData] = useState<any | null>();
  const [dataCalculator, setDataCalculator] = useState<CalculatorType | null>(
    null
  );

  useEffect(() => {
    const data = sessionStorage.getItem("simulation");
    console.log(data);
    if (data) {
      const obj = JSON.parse(data);
      setSessionStorageData(obj);
      const objCalculator = calcular(
        parseFloat(obj.accountValue),
        obj.typeInstallation
      );
      console.log(obj.accountValue);
      if (objCalculator) {
        setDataCalculator(objCalculator);
      }
    }

    // }
  }, []);

  const ButtonOrcamento = async () => {
      navigate("/solicitar-orcamento");
  };
  return (
    <Grid>
      <Header headerCenter={true}></Header>
      <Modal
        open={isLoading}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="md" />
      </Modal>

      <Grid px={2} >
      <Grid
        maxWidth={1280}
        py={4}
        m={"auto"}
        display={"flex"}
        flexDirection="column"
        gap={4}
      >
        <Stack
          sx={{
            "@media (max-width: 1200px)": {
              flexDirection: "column",
              display: "flex",
            },
          }}
        >
          <Typography
          fontSize={48}
          fontWeight={700}
          sx={{ color: "#FFFFFF" }}
          mb={4}
          textAlign={"center"}
        >
         Resultado da Simulação
        </Typography>
          <Grid display={'flex'} sx={{
            "@media (max-width: 720px)": {
              flexDirection: "column",
            },
          }} direction={'row'} gap={4}>
            <Stack
              direction="column"
              p={2}
              flex={1}
              gap={2}
              alignItems={"center"}
              bgcolor={"#112A3A"}
              sx={{ borderColor: "#747474" }}
              borderRadius={6}

            >
              <Box>
                <img width={48} src="/investir-energia.svg" alt="" />
              </Box>
              <Box>
                <Typography fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Estimativa de investimento
                </Typography>
                <Typography
                mt={2}
                textAlign={'center'}
                  sx={{ color: "#4caf50" }}
                  fontSize={24}
                  fontWeight={700}
                >
                  {dataCalculator?.valorInvestimento.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              p={2}
              gap={2}
              flex={1}
              alignItems={"center"}
              bgcolor={"#112A3A"}
              sx={{ borderColor: "#747474" }}
              borderRadius={6}

            >
              <Box>
                <img width={48} src="/investir-energia.svg" alt="" />
              </Box>
              <Box>
                <Typography  fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Economia mensal
                </Typography>
                <Typography
                mt={2}
                  sx={{ color: "#4caf50" }}
                  textAlign={'center'}
                  fontSize={24}
                  fontWeight={700}
                >
                  {dataCalculator?.economiaMensal.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              p={2}
              gap={2}
              flex={1}
              alignItems={"center"}
              bgcolor={"#112A3A"}
              sx={{ borderColor: "#747474" }}
              borderRadius={6}
            >
              <Box>
                <img width={48} src="/payback.svg" alt="" />
              </Box>
              <Box className="font-semibold flex flex-col gap-2">
                <Typography fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Retorno de investimento
                </Typography>
                <Typography
                mt={2}
                textAlign={'center'}
                  sx={{ color: "#4caf50" }}
                  fontSize={24}
                  fontWeight={700}
                >
                  {Math.round(dataCalculator?.payback as number)} meses
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Stack>
        <Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            "@media (max-width: 800px)": {
              flexDirection: "column",
              alignItems: 'start'
            },
          }}
          p={6}
          gap={4}
          alignItems={"center"}
          borderRadius={6}
        >
          <Stack direction="row" gap={4} alignItems={"center"}>
            <img width={68} src="energia-limpa.svg" alt="" />
            <Typography
              fontSize={20}
              fontWeight={700}
              sx={{ color: "#FFFFFF" }}
            >
              ENERGIA LIMPA
            </Typography>
          </Stack>
          <Divider orientation="vertical" sx={{ bgcolor: "#ffffff" }}></Divider>
          <Stack direction="row" gap={4} alignItems={"center"}>
            <img width={68} src="credit.svg" alt="" />
            <Typography
              fontSize={20}
              fontWeight={700}
              sx={{ color: "#FFFFFF" }}
            >
              CRÉDITO NA CONTA DE LUZ
            </Typography>
          </Stack>
          <Divider orientation="vertical" sx={{ bgcolor: "#ffffff" }}></Divider>
          <Stack direction="row" gap={4} alignItems={"center"}>
            <img width={68} src="economia.svg" alt="" />
            <Typography
              fontSize={20}
              fontWeight={700}
              sx={{ color: "#FFFFFF" }}
            >
              ECONOMIA POR 25 ANOS
            </Typography>
          </Stack>
        </Stack>
        </Grid>
        <Grid  width={"100%"} gap={6}>

        <Grid width={'100%'}>
        <Grid
            width={"100%"}
            display={"flex"}
            sx={{
              "@media (max-width: 720px)": {
                flexDirection: "column",
              },
            }}
            flexDirection={"row"}
            gap={4}
          >
            <Stack
              direction="column"
              gap={2}
              alignItems={"center"}
              flex={1}
              p={2}
              bgcolor={"#112A3A"}
              borderRadius={6}
              sx={{ borderColor: "#747474" }}
            >
              <Box>
                <img width={48} src="/potencia.svg" alt="" />
              </Box>
              <Box>
                <Typography fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Potência estimada
                </Typography>
                <Typography
                mt={2}
                textAlign={'center'}
                  fontSize={24}
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  {dataCalculator?.tamanhoKWP.toFixed(2)} kWp
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              alignItems={"center"}
              gap={2}
              flex={1}
              p={2}
              bgcolor={"#112A3A"}
              borderRadius={6}
              
              sx={{ borderColor: "#747474" }}
            >
              <Box>
                <img width={48} src="/metros.svg" alt="" />
              </Box>
              <Box>
                <Typography fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Área do telhado
                </Typography>
                <Typography
                mt={2}
                textAlign={'center'}
                  fontSize={24}
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  {dataCalculator?.areaTelhado.toFixed(2)} m²
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              alignItems={"center"}
              gap={2}
              flex={1}
              p={2}
              bgcolor={"#112A3A"}
              borderRadius={6}
              sx={{ borderColor: "#747474" }}
            >
              <Box>
                <img width={48} src="/prduçao1.svg" alt="" />
              </Box>
              <Box>
                <Typography fontSize={20} textAlign={'center'} sx={{ color: "#FFFFFF" }}>
                  Produção estimada
                </Typography>
                <Typography
                mt={2}
                textAlign={'center'}
                  fontSize={20}
                  fontWeight={700}
                  sx={{ color: "#4caf50" }}
                >
                  {dataCalculator?.geracaoMensalKWH
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  kWh/ mês
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
          
          
        </Grid>
        <Grid display={'flex'} justifyContent={'space-between'}>
          <Button
          onClick={()=>{navigate('/simulador-de-economia')}}
            sx={{
              borderColor: "#4caf50",
              color: "#4caf50",
              ":hover": { backgroundColor: "#3b943e", color: "#FFFFFF" },
            }}
            variant="outlined"
            size="lg"
            startDecorator={<LoopIcon />}
          >
            Refazer simulação
          </Button>
          {/* <Button
            sx={{ backgroundColor: "#4caf50" }}
            variant="solid"
            size="lg"
            startDecorator={<FileDownloadOutlinedIcon />}
          >
            Baixar simulação
          </Button> */}
          <Button
            sx={{
              backgroundColor: "#4caf50",
              ":hover": { backgroundColor: "#3b943e" },
            }}
            variant="solid"
            size="lg"
            onClick={ButtonOrcamento}
          >
            Realizar orçamento
          </Button>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultSimulator;
